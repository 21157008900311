body {
  font-family: 'Poppins', sans-serif; /* Modern font */
  background: #f0f2f5; /* Light gray background for a clean look */
  color: #333; /* Dark text color */
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
}

/* Header Styles */
.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background: linear-gradient(90deg, #ff9100 0%, #ff6d00 100%); /* Orange gradient background */
  color: #ffffff; /* White text color */
  display: flex;
  justify-content: flex-end; /* Align items to the right */
  align-items: center; /* Center vertically */
  padding: 15px 30px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15); /* Subtle shadow */
  z-index: 1000;
  box-sizing: border-box;
}

.header-content {
  display: flex;
  justify-content: flex-end;
  width: calc(100% - 40px);
  max-width: 1200px;
  margin: 0 auto;
}

.header-buttons {
  display: flex;
  gap: 20px;
}

.header-button {
  background: #ffffff;
  border: 1px solid #ff9100;
  padding: 10px 20px;
  font-size: 1em;
  cursor: pointer;
  color: #ff9100;
  border-radius: 5px;
  transition: background-color 0.3s, color 0.3s, transform 0.2s;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Slight shadow for button */
}

.header-button:hover {
  background: #ff9100;
  color: #ffffff;
  transform: translateY(-2px); /* Lift effect */
}

.logo-header { 
  display: flex;
  align-items: center;
}
.logo {
  height: 60px; /* Adjust the size as needed */
  margin-right: 1200px; /* Spacing between logo and navigation */
}

/* Modal Styles */
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7); /* Darker semi-transparent background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1001;
  opacity: 0;
  visibility: hidden; /* Hidden by default */
  transition: opacity 0.3s ease, visibility 0.3s ease;
}

/* Modal Background Active */
.modal.show {
  opacity: 1;
  visibility: visible; /* Visible when active */
}

/* Modal Content */
.modal-content {
  background: #ffffff;
  padding: 20px; /* Reduced padding */
  border-radius: 8px; /* Rounded corners */
  width: 400px; /* Wider for a spacious feel */
  box-shadow: 0 12px 24px rgba(0, 0, 0, 0.15); /* Softer shadow */
  text-align: center;
  color: #333; /* Darker text */
  transition: transform 0.3s ease;
  transform: scale(0.9); /* Initial scale */
}

/* Modal Content Active */
.modal.show .modal-content {
  transform: scale(1); /* Scale up */
}

/* Buttons */
.modal-button,
.modal-close-button {
  margin-top: 15px;
  padding: 12px 20px;
  border: none;
  background: #ff9100; /* Orange background */
  color: #ffffff; /* White text */
  cursor: pointer;
  border-radius: 5px;
  font-size: 1em;
  font-weight: 600; /* Bolder text */
  transition: background-color 0.3s ease, transform 0.2s ease; /* Smooth hover effect */
  margin-left: 10px;
}

.modal-button:hover,
.modal-close-button:hover {
  background: #e06d00; /* Darker orange on hover */
  transform: translateY(-2px); /* Lift effect */
}

/* Modal Options */
.modal-options label {
  display: block;
  margin-top: 10px;
  font-size: 1em;
  color: #333;
  font-weight: 500; /* Slightly bolder text */
}

.modal-options input[type="radio"] {
  margin-right: 10px;
}

.modal-content input[type="number"]{
  border-radius: 20%;
  padding: 10px 20px;
  font-size: 1em;
  border: 1px solid #ddd;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Light shadow */
  transition: box-shadow 0.3s ease, border-color 0.3s ease;
}

.modal-content p{
  font-family:sans-serif;
  font-weight: bold;
  padding: 10px 20px;
  font-size: 1em;
}

/* Item List Styles */

.item-list {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
  margin: 40px auto;
  max-width: 1200px;
}

/*Item styles */

.item {
  flex: 1 1 calc(20% - 20px);
  max-width: calc(20% - 20px);
  display: flex;
  flex-direction: column;
  background: #ffffff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  box-sizing: border-box;
  transition: transform 0.3s, box-shadow 0.3s;
  color: #333;
  height: 250px; /* Set a fixed height for all items */
  position:relative; /* Allows for absolute positioning inside the item */
  overflow: hidden; /* Prevents content overflow */
  margin-bottom: 20px;
}

.item:only-child{
  flex: 1 1 100%; /* Makes single item take full width */
  max-width: 60%; /* Sets max width to 100% for single item */
}

.item:hover {
  transform: translateY(-5px); /* Lift effect */
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.item h3 {
  margin: 0 0 10px 0;
  font-size: 1.2em;
  color: #ff6d00; /* Orange color for item names */
}

.item p {
  margin: 0px ;
  font-size: 1em;
}

/* Styles for price */
.item-price {
  position: absolute;
  bottom: 10px;
  left: 20px;
  font-weight: bold;
  color: #333; /* Darker color for price */
  z-index: 1; /* Ensures it stays above other elements */
}


/* Styles for temperature */
.item-temp {
  position: absolute;
  bottom: 10px;
  right: 20px;
  display: flex;
  align-items: center;
  font-size: 1em;
  z-index: 1; /* Ensures it stays above other elements */
}

.temperature-hot {
  color: red;
  margin-right: 5px;
}

.temperature-cold {
  color: blue;
  margin-right: 5px;
}

/* Search Bar Styles */
.search-bar-container {
  display: flex;
  padding: 20px;
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
}
.search-bar {
  width: 100%;
  max-width: 400px; /* Max width to prevent it from getting too wide */
  padding: 10px 20px;
  font-size: 1em;
  border: 1px solid #ddd;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Light shadow */
  transition: box-shadow 0.3s ease, border-color 0.3s ease;
  
}

.search-bar:focus {
  outline: none;
  border-color: #ff9100; /* Orange border on focus */
  box-shadow: 0 2px 8px rgba(255, 145, 0, 0.2); 
}

.filter-container {
  display: flex;
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  margin: 20px 0; /* Space around the filter */
}

/* Filter Bar Styles */
.filter-label {
  font-size: 1em;
  color: #333;
  margin-right: 10px; /* Space between label and select */
}

.filter-select {
  padding: 10px;
  font-size: 1em;
  border: 1px solid #ccc;
  border-radius: 5px;
  background: #fff;
  transition: border-color 0.3s, box-shadow 0.3s;
}

.filter-select:hover,
.filter-select:focus {
  border-color: #ff9100; /* Highlight border on hover/focus */
  box-shadow: 0 0 5px rgba(255, 145, 0, 0.3); /* Subtle glow effect */
}